import React, { useState } from 'react'
import style from './PrimaryProductCard.module.scss'

const PrimaryProductCard = ({
  title,
  alt_name,
  is_new,
  is_hit,
  price,
  price_before_text,
  price_after_text,
  colors,
  card_image,
}) => {
  const [currentColor, setCurrentColor] = useState(0)

  return (
    <div className={style.root}>
      <div className="flex flex-wrap -mx-4">
        <div className="p-4 w-full md:w-1/2" />
        <div className="p-4 w-full md:w-1/2">
          <h2>
            <span>{title}</span>
            {alt_name && (
            <span>
|
              {alt_name}
            </span>
)}
          </h2>
          <div className="mt-4">
            {price_before_text}
            <strong>{price}</strong>
            {price_after_text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrimaryProductCard
