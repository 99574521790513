import React from 'react'
import HorizontalScrolling from '../HorizontalScrolling'
import style from './CatalogPage.module.scss'
import PrimaryProductCard from '../PrimaryProductCard'

const CatalogPage = ({
  data: {
    allWordpressWpProduct: { nodes: products },
  },
}) => (
  <HorizontalScrolling className="items-center">
    <div className={style.verticalTitle}>
      <h1>Каталог</h1>
    </div>
    <div className={style.container}>
      {products.map(product => (
        <PrimaryProductCard key={product.id} {...product} />
      ))}
    </div>
  </HorizontalScrolling>
)

export default CatalogPage
